.container {
    border: 2px dashed var(--chakra-colors-gray-400);
    max-width: 100%;
    height: 250px;

}

.button {
    font-size: .75rem;
    color: #FC2E5D;
}