.loading {
    animation: is-rotating 1s infinite;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 3px solid rgba(0,0,0, 0.1);
    border-top-color: white;
}

@keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }