.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;

    &.closed {
        .sidebar {
            width: 100px;

            .navbrand {
                img {
                    display: none;
                }
            }
            ul li.title {
                display: none;
            }
            ul li a {
                align-items: center;
                justify-content: center;
            }
            ul li a span:last-child{
                display: none;
            }
            ul li.actionLogout {
                span:last-child{
                    display: none;
                }
            }
        }
        .container {
            width: calc(100% - 100px);
            left: 100px;
        }
    }
}

.sidebar {
    height: 100%;
    width: 250px;
    background: #2B2928;
    // padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s;
    position: fixed;
}

.navbrand {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        max-width: 80%;
    }
}

.navbar {
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    ul {
        list-style: none;

        li {
            display: block;
            width: 100%;

            a {
               display: flex;
                padding: 1rem;
                color: #fff;
                gap: 1rem;
                font-weight: 500;
                font-size: .75rem;
                width: 100%;
                align-items: center;

                &:hover {
                    background: #312E2D;
                }

                &.isActive {
                    background: #312E2D;
                    color: var(--chakra-colors-brand-100);
                }
            }

            &.title {
                color: #676767;
                padding: 1rem;
                font-size: .75rem;
                letter-spacing: 0.18rem;
            }

            &.profile > span{
                color: #676767;
            }
            filter: brightness();

        }
    }
}

.actionLogout {
    padding: 1rem;
}

.container {
    width: calc(100% - 250px);
    height: 100%;
    background: #F3F7F9;
    transition: .2s;
    position: relative;
    left: 250px;
    overflow-y: auto;
    
    .content {
        margin-top: 64px;
        padding: 2.5rem 2rem;
        height: fit-content;
    }
}

.topMenu {
    width: inherit;
    padding: 1rem 2rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 5;
}

.creditsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--chakra-colors-gray-500);
    
    .credits {
        display: flex;
        align-items: center;
        gap: .5rem;

        p {
            font-size: .875rem;
        }
    }

    button {
        
    }
}
