.wrapper {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: minmax(720px, 1fr) 2fr;
}

.containerForm {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
    > div {
        width: 100%;
        max-width: 450px;
    }
}

.containerForm img {
    position: absolute;
    top: 4rem;
}

.containerForm input + input {
    margin-top: 1rem;
}

.containerForm .register-text {
    text-align: center;
    margin-top: 1rem;
}

.background {
    background: linear-gradient(180deg, #3F6EF0 0%, #3639A4 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h1 {
        font-size: 2.5rem;
        font-weight: 900;
        color: #fff;
    }

    > p {
        font-size: 1.25rem;
        font-weight: normal;
        line-height: 1.2;
        color: #fff;
        text-align: center;
    }

    img {
        margin-top: 2.5rem;
    }
}

// .background img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     object-position: center;
//     opacity: .3;
// }