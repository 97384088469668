.container {}

.list {
    list-style: none;
    position: relative;

    li {
        margin-left: 3rem;

        &::before {
            content: '';
            height: 100%;
            width: 1px;
            background: #E5E5E5;
            position: absolute;
            left: 1rem;
        }

        &:last-child:before {
            display: none;
        }
        & + li {
            margin-top: 2rem;
        }
    }
}

.type {
    display: flex;
    height: 2rem;
    width: 2rem;
    border-radius: 2rem;
    align-items: center;
    justify-content: center;
    background: #E2E8F0;
    color: #676767;
    position: absolute;
    left: 0;
}

.info {
    display: flex;  
    gap: 1rem;

    p {
        font-size: 1rem;
        color: #676767;
    }
}

.content {
    padding: 1.5rem 1rem;
    background: #F7FAFC;
    border: 1px solid #E2E8F0;
    border-radius: 5px;
    margin-top: 1.5rem;
}