.editor {
    border: 1px solid var(--chakra-colors-gray-100);
    border-radius: 0 0 2px 2px;
    padding-left: .5rem;
    min-height: 100px;
}

.toolbar {
    background: var(--chakra-colors-gray-100);
    color: var(--chakra-colors-gray-600);
    border: 0;
    padding: 1rem .25rem;
    margin-bottom: 0;
}

.button {
    opacity: 0.7;
    background: transparent;
    padding: 0.25rem;
    border: 0;

    &:hover {
        filter: brightness(0.8);
        box-shadow: none;
    }
    
    &.rdw-option-active {
        box-shadow: none;
    }
}