.buttonAvatarCancel {
    color: var(--chakra-colors-red-500);
    transition: .2s ease;

    &:hover {
        filter: brightness(0.8);
    }
}   

.buttonAvatarSave {
    color: var(--chakra-colors-green-500);
    transition: .2s ease;

    &:hover {
        filter: brightness(0.8);
    }
}   